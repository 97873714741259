ul {
    padding: 0 $spacer * 4;
}

// .list-style--square {
//     list-style: square;
// }

// .list-style--none {
//     list-style: none;
// }

.list-style-colored--light {
    ul {
        /* add these styles in ul
        mmb-flex mmb-flex-col mmb-gap-2
        */ 
        list-style: none;
        margin-left: $spacer * 3;

        li {
            /*add these styles to li items
            before:mmb-content-[''] before:mmb-mt-2 before:-mmb-ml-5 before:mmb-absolute before:mmb-w-2 before:mmb-h-2 before:mmb-bg-bullet-point
            */
            margin-bottom: $spacer * 2;

            &::before {
                content: "";
                margin-top: 6px;
                margin-left: -20px;
                position: absolute;
                width: $spacer * 2;
                height: $spacer * 2;
                background-image: url('/assets/images/icons/bullet-icon.png');
            }
        }
    }
}

.list-item-selected {
    background: rgba(52, 152, 219, 0.2);
}

.list-style-none {
    list-style: none;
}