/**
percentage --> 25 / 50 / 75 / 100
px --> $spacer multiples (h-1 = height: $spacer)
**/
@mixin create-height-utils {
    @for $i from $spacer-range-start through $spacer-range-end * 10 {

        // TODO: Add a if to only create below classes when multiple of $spacer
        .h-#{$i}px {
            height: #{$i}px !important;
        }

        .minh-#{$i}px {
            min-height: #{$i}px;
        }

        .maxh-#{$i}px {
            max-height: #{$i}px;
        }
    }
}

@include create-height-utils;



/**
TODO: Delete below code
**/
/*
Heights
==
3 variations - px, % and vh
*/
.h-auto {
    height: auto !important;
}

.h-min-auto {
    min-height: auto !important;
}

.h-100 {
    height: 100%;
}

.h-100vh {
    height: 100vh;
}

.mh-100 {
    min-height: 100% !important;
}

.min-h1000vh {
    min-height: $spacer * 250;
}

.minh-100vh--navbar-adjust {
    min-height: calc(100vh - 60px);
}

.maxh-100vh--navbar-adjust {
    max-height: calc(100vh - 160px);
}

// .minh-100vh--navbar-footer-adjust {
//     min-height: calc(100vh - 122px);
// }

// .minh-100vh-no-results-patch {
//     min-height: calc(100vh - 780px);

//     &.is-small {
//         min-height: calc(100vh - 600px);
//     }
// }


.mh-100-navbar-adjustment {
    overflow: scroll;
    min-height: calc(100vh - 125px) !important;
}

.h-100vh-navbar-adjust {
    height: calc(100vh - 76px);
}

.minh-100vh-navbar-adjust {
    min-height: calc(100vh - 150px);
}

.mh-24 {
    max-height: 24px;
    overflow: hidden;
}

.minh-60px {
    min-height: 60px;
}

.minh-90px {
    min-height: 90px;
}
