button:disabled{
    @apply mmb-cursor-not-allowed;
}

.mmb-primary-btn{
    text-decoration: none !important;
    @apply mmb-px-10 mmb-button mmb-py-4 mmb-leading-6 mmb-rounded-full; 
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-bg-theme_blue-600 mmb-text-white hover:mmb-bg-theme_blue-400;
    @apply mmb-transition-all mmb-ease-in-out mmb-duration-200 hover:mmb-text-white;
    @apply disabled:mmb-bg-theme_light-50 disabled:mmb-text-theme_light-150 disabled:mmb-pointer-events-none disabled:mmb-border-theme_light-50;
}

.mmb-success-btn-light{
    text-decoration: none !important;
    @apply mmb-px-10 mmb-button mmb-py-4 mmb-leading-6 mmb-rounded-full; 
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-bg-theme_success-300 mmb-text-white hover:mmb-opacity-75;
    @apply mmb-transition-all mmb-ease-in-out mmb-duration-200 hover:mmb-text-white;
    @apply disabled:mmb-bg-theme_light-50 disabled:mmb-text-theme_light-150 disabled:mmb-pointer-events-none disabled:mmb-border-theme_light-50;
}

.mmb-secondary-btn{
    @apply mmb-button mmb-rounded-full mmb-py-2 mmb-px-5 mmb-leading-6;
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-bg-theme_light-100 mmb-text-primary-600 mmb-border mmb-border-theme_light-100;
    @apply mmb-cursor-pointer;
    @apply hover:mmb-bg-theme_blue-100 hover:mmb-border-theme_blue-100;
    @apply active:mmb-bg-theme_blue-200 active:mmb-border-theme_blue-200 active:mmb-outline-none;
    @apply focus:mmb-bg-theme_blue-200 focus:mmb-border-theme_blue-200 focus:mmb-outline-none;
    @apply mmb-transition-colors mmb-ease-in-out mmb-duration-200;
}

.mmb-accent-btn{
    @apply mmb-bg-theme_black-900 mmb-button mmb-py-4 mmb-px-4 mmb-rounded-full mmb-text-white mmb-flex;
    @apply mmb-justify-center mmb-items-center mmb-cursor-pointer mmb-border-1 mmb-border-black; 
    @apply disabled:mmb-pointer-events-none disabled:mmb-opacity-50; 
    @apply hover:mmb-bg-theme_black-500 hover:mmb-border-theme_black-500;
    @apply active:mmb-bg-theme_black-300 active:mmb-border-theme_black-300 active:mmb-outline-none;
    @apply focus:mmb-bg-theme_black-300 focus:mmb-border-theme_black-300 focus:mmb-outline-none;
    @apply mmb-transition-colors mmb-ease-in-out mmb-duration-200;
}

.mmb-tertiary-btn{
    text-decoration: none !important;
    @apply mmb-button mmb-rounded-full mmb-py-2 mmb-px-5 mmb-leading-6 mmb-bg-transparent;
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-border-1 mmb-border-primary-600 mmb-text-primary-600;
    @apply hover:mmb-border-theme_light-100 hover:mmb-bg-theme_light-100;
    @apply active:mmb-border-theme_blue-100 active:mmb-bg-theme_blue-100 active:mmb-outline-none;
    @apply focus:mmb-border-theme_blue-100 focus:mmb-bg-theme_blue-100 focus:mmb-outline-none;
    @apply mmb-transition-colors mmb-ease-in-out mmb-duration-200;
    @apply disabled:mmb-bg-theme_light-50 disabled:mmb-text-theme_light-150 disabled:mmb-pointer-events-none disabled:mmb-border-theme_light-50;
}

.mmb-outline-btn{
    text-decoration: none !important;
    @apply mmb-button mmb-rounded-full mmb-py-2 mmb-px-5 mmb-leading-6 mmb-bg-transparent;
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-border-1 mmb-border-white mmb-text-white;
    @apply hover:mmb-border-primary-600 hover:mmb-bg-primary-600;
    @apply active:mmb-border-theme_blue-100 active:mmb-bg-theme_blue-100 active:mmb-outline-none;
    @apply focus:mmb-border-theme_blue-100 focus:mmb-bg-theme_blue-100 focus:mmb-outline-none;
    @apply mmb-transition-colors mmb-ease-in-out mmb-duration-200;
    @apply disabled:mmb-bg-theme_light-50 disabled:mmb-text-theme_light-150 disabled:mmb-pointer-events-none disabled:mmb-border-theme_light-50;
}

.mmb-success-btn{
    text-decoration: none !important;
    @apply mmb-px-10 mmb-button mmb-py-4 mmb-leading-6 mmb-rounded-full; 
    @apply mmb-text-center;
    @apply mmb-cursor-pointer disabled:mmb-cursor-not-allowed;
    @apply mmb-bg-theme_success-500 mmb-text-white hover:mmb-opacity-85;
    @apply mmb-transition-all mmb-ease-in-out mmb-duration-200 hover:mmb-text-white;
    @apply disabled:mmb-bg-theme_light-50 disabled:mmb-text-theme_light-150 disabled:mmb-pointer-events-none disabled:mmb-border-theme_light-50;
}