.mmb-icon-xs {
    @apply mmb-text-xxs;
}

.mmb-icon-sm {
    @apply mmb-text-xs;
}

.mmb-icon-md {
    @apply mmb-text-base mmb-w-12;
}

.mmb-icon-lg {
    @apply mmb-text-xl;
}

.mmb-icon-xl {
    @apply mmb-text-2xl;
}

.mmb-logo-xs {
    @apply mmb-text-xl;
}

.mmb-logo-sm {
    @apply mmb-text-4xl;
}

.mmb-icon i {
    @apply mmb-title mmb-text-white mmb-opacity-35;
}

// .mmb-icon-container:hover i {
//     @extend .opacity-fill,
//     .box-shadow-md;
// }