// to be removed later
$font-family-base: "Open Sans";
$font-family-base-light: "Open Sans Light";
$font-family-base-semi-bold: "Open Sans SemiBold";
$font-family-base-bold: "Open Sans Bold";

$font-family-variant: "Helvetica Now Display", sans-serif;
$font-family-variant-medium: "Helvetica Now Display Medium", sans-serif;
$font-family-variant-light: "Helvetica Now Display ExtLt", sans-serif;
$font-family-variant-bold: 'Helvetica Now Display Bold';
// to be removed later



@font-face {
    font-family: 'Helvetica Now Display ExtLt';
    src: local('Helvetica Now Display ExtLt'), local('HelveticaNowDisplay-ExtLt'),

        url(../fonts/HelveticaNowDisplay-ExtLt.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display ExtBlk';
    src: local('Helvetica Now Display ExtBlk'), local('HelveticaNowDisplay-ExtBlk'),
        url(../fonts/HelveticaNowDisplay-ExtBlk.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Bold';
    src: local('Helvetica Now Display Bold'), local('HelveticaNowDisplay-Bold'),
        url(../fonts/HelveticaNowDisplay-Bold.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: local('Helvetica Now Display Regular'), local('HelveticaNowDisplay-Regular'),
        url(../fonts/HelveticaNowDisplay-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Light';
    src: local('Helvetica Now Display Thin'), local('HelveticaNowDisplay-Thin'),
        url(../fonts/HelveticaNowDisplay-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display ExtBd';
    src: local('Helvetica Now Display ExtBd'), local('HelveticaNowDisplay-ExtraBold'),
        url(../fonts/HelveticaNowDisplay-ExtraBold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Medium';
    src: local('Helvetica Now Display Medium'), local('HelveticaNowDisplay-Medium'),
        url(../fonts/HelveticaNowDisplay-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Black';
    src: local('Helvetica Now Display Black'), local('HelveticaNowDisplay-Black'),
        url(../fonts/HelveticaNowDisplay-Black.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Hairline';
    src: local('Helvetica Now Display Hairline'), local('HelveticaNowDisplay-Hairline'),
        url(../fonts/HelveticaNowDisplay-Hairline.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'),
        url(../fonts/OpenSans.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: local('Open Sans Bold'), local('OpenSansBold'),
        url(../fonts/opensans-bold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: local('Open Sans SemiBold'), local('OpenSansSemiBold'),
        url(../fonts/opensans-bold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Light';
    src: local('Open Sans Light'), local('OpenSansLight'),
        url(../fonts/opensans-light.ttf) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}