.mmb-emphasize-left-primary {
  border-left: 3px solid $primary;
  @extend .pl-5;
  text-transform: uppercase;
}

/* TODO
Need to remove  "mmb-perm-title" class from all pages.
After remove "mmb-perm-title" class from pages we will remove this class as well.
*/
.mmb-perm-title,
.mmb-emphasize-left-secondary {
  border-left: 3px solid $secondary-300;
  @extend .pl-3;

  // replace with the following: mmb-border-l-3 mmb-border-primary-600 mmb-pl-3
}