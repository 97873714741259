.background-none {
  background-color: transparent !important;
}
.text-light-blue {
  color: $light-blue !important;
}

.text-primary-500 {
  color: $primary-500;
}

.text-primary-700 {
  color: $primary-700;
}

.text-primary {
  color: $primary;
}

.text-purple {
  color: $purple !important;
}

.text-bright-green {
  color: $bright-green;
}

.text-dark-900 {
  //replace with mmb-text-theme_black-100
  color: $black-900;
}

.text-dark-600 {
  color: $black-600;
}

.text-dark-500 {
  color: $black-500;
}

.text-dark-400 {
  color: $black-400;
}

.text-dark-100 {
  //replace with mmb-text-theme_black-900
  color: $black-100;
}

.text-dark-200 {
  color: $black-200;
}

.text-dark-300 {
  // replace with mmb-text-theme_black-500
  color: $black-300;
}

.text-secondary-300 {
  color: $secondary-300 !important;
}

// .text-success {
//   color: $success !important;
// }

.text-white {
  color: $page-text-white !important;
}

.text-black {
  // replace with this class mmb-text-theme_black-900
  color: $text-black;
}

.text-linkedin-primary {
  color: $linkedin-primary;
}

.text-light-black {
  color: $light-black;
}

.gray-bg {
  background-color: $buttons-disabled !important;
}

// .bg-dark-onboarding {
//   background-color: $bg-dark-onboarding !important;
// }

.text-grey {
  color: $page-text-grey;
}

.text-grey--light {
  color: $other;
}

.text-green {
  color: $notification-affirmative;
}

.text-red {
  /*
  replace this with the following
  mmb-text-theme_red-600
  */
  color: $notification-error;
}

.text-black-600 {
  color: $black-600;
}

.text-gray-disabled {
  color: $text-gray-disabled;
}

.text-primary-900 {
  color: $primary-900;
}

.text-warning {
  color: $warning;
}

.text-green-light {
  color: $investment;
}

.text-orange {
  color: $orange;
}

// .text-closed {
//   color: $closed-status;
// }

.text-lightest {
  color: $light-border;
}

.bg-grey {
  background-color: #DCDCDC;
}

// .bg-primary-900 {
//   background-color: $primary-900;
// }

.bg-primary-500 {
  background-color: $primary-500;
}

.bg-primary-100 {
  background-color: $primary-100;
}

.bg-linkedin-primary {
  background-color: $linkedin-primary;
}

.bg-twitter-primary {
  background-color: $twitter-primary;
}

.bg-black {
  background-color: $text-black !important;
}

.bg-dark {
  background-color: $dark !important;
}

// .bg-dark--opaque {
//   background-color: rgba(0, 0, 0, 0.5);
// }

.bg-darkest {
  background-color: $black-100 !important;
}

.bg-darkest-700 {
  background-color: $blue-dark;
}

.bg-darkest-900 {
  background-color: $blue-darkest;
}

.bg-gray--900 {
  background-color: $gray-900 !important;
}

// .bg-gray-nav {
//   background-color: $bg-gray-nav;
// }

// .bg-dark--500 {
//   background-color: $dark--500 !important;
// }

// .bg-dark--800 {
//   background-color: $dark--800 !important;
// }

.bg-purple {
  background-color: $purple !important;
}

.bg-dark-pink {
  background-color: $dark-pink !important;
}

.bg-light-blue {
  background-color: $light-blue !important;
}

.bg-typeform {
  background-color: $typeform-color;
}

.bg-typeform-event {
  background-color: #222;
}

.bg-typeform-career {
  background-color: #222;
}

.bg-typeform-initiative {
  background-color: #222;
}

.bg-dark-blue {
  background-color: $dark-blue !important;
}

.bg-btn-secondary-500 {
  background-color: $btn-secondary-5 !important;
}

.bg-buttons-primary {
  background-color: $buttons-primary;
}

// .bg-page-backgorund {
//   background: $page-text-page-background;
// }

.bg-dark-900 {
  background-color: $black-900;
}

.bg-dark-gray {
  background-color: $bg-dark-gray;
}

.bg-white {
  background-color: $white-bg-fff !important;
}

.bg-off-white {
    background-color: $off-white;

}

// .bg-primary-brand-contrast-section {
//   background-color: $primary-brand-contrast-section
// }

.bg-red {
  background-color: $co-founder !important;
}

.bg-info {
  background-color: $connection !important;
}

.bg-light-contrast {
  background-color: $page-bg-light-contrast;
}

.bg-secondary-300 {
  background-color: $secondary-300;
}

.bg-success {
  background-color: $success;
}

.bg-danger {
  background-color: $danger;
}

.bg-success-900 {
  background-color: $success-900;
}

.bg-success-light {
  background-color: $success-light;
}

.bg-black {
  background-color: $text-black;
}

// .bg-dark-200 {
//   background-color: $black-200;
// }

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-secondary-500 {
  background-color: $secondary-500;
}

.bg-secondary-1000 {
  background-color: $secondary-1000;
}

.bg-lightest {
  background-color: $bg-lightest !important;
}

.bg-light {
  background-color: $bg-light !important;
}

.bg-secondary--1000 {
  background-color: $secondary-1000;
}

// .bg-secondary-light {
//   background-color: $secondary-light;
// }

.bg-section-light-grey {
  background-color: $section-bg-light-grey;
}

.bg-disabled {
  background-color: $buttons-disabled;
}

// .bg-light-color {
//   background-color: $light-color;
// }

// .bg-dark-black--200 {
//   background-color: $black-200;
// }

// .bg-light-white {
//   background-color: $light-white-bg;
// }

.bg-lightest--gray {
  background-color: $bg-lightest--gray;
}

.bg-primary-700 {
  background-color: $primary-700;
}

// .bg-primary-800 {
//   background-color: $primary-800;
// }

// .bg-light-blue-100 {
//   background-color: $light-blue-100;
// }

.bg-highlight {
  background-color: $bg-yellow;
}

.bg-secondary--light {
  background-color: $buttons-secondary;
}

.bg-linear-gradient-light-blue {
  background-image: linear-gradient(to bottom, rgba(245, 249, 255, 0.5), rgba(232, 241, 255, 0.5));
}

.bg-linear-gradient-dark {
  background-image: linear-gradient(to bottom, #111, #2a2f34);
}

// .bg-linear-gradient-dark-blue {
//   background-image: linear-gradient(to right, #01216e 0%, #023998);
// }

// .bg-category-tag {
//   background-image: linear-gradient(to right, rgba(17, 17, 17, 0) 0%, #111 100%);
// }

.bg-dark--overlay {
  background-color: $dark-overlay;
}

.bg-white-lightest {
  background-color: $white-lightest;
}

.bg-grey--light {
  background-color: $other;
}

// .bg-drop-down {
//   background: $dropdown-bg;
// }

.bg-onbording--light {
  background-color: $light-background-onboarding;
}

.about-us-section-1 {
  background: $about-section-bg-color;
}

.about-us-section-2 {
  background: $about-section-bg-color-2;
}

.about-us-section-problem {
  background: $about-problem-section;
}

// .problem-box-dark {
//   background-color: $problem-box-1;
// }

.problem-box-light {
  background-color: $problem-box-2;
}

.circle-number {
  background-color: $circle-number;
}

// .text-gold {
//   color: $special-btn;
// }

// .home-section-inspired {
//   background: rgb(71, 77, 84);
//   background: radial-gradient(circle, rgba(71, 77, 84, 1) 0%, rgba(37, 44, 52, 1) 100%);
// }

// .dark-brown {
//   background-color: $dark-brown;
// }

.sky-blue {
  background-color: $sky-blue;
}

// .gray-btn {
//   background-color: $btn-color--secondary;
// }

.gray-400 {
  background-color: $gray-400;
}

// .bg-gray--light {
//   background-color: $gray--light
// }

// .bg-gray--dark {
//   background-color: $gray--dark;
// }

.pricing-starter {
  background: linear-gradient(0deg, #A07349, #A07349),
    radial-gradient(42.74% 45.27% at 54.75% 45.27%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;

}

.pricing-growth {
  background: linear-gradient(0deg, #9B9B9B, #9B9B9B),
    radial-gradient(58.33% 58.33% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.price-advance {
  background: linear-gradient(0deg, #C29B10, #C29B10),
    radial-gradient(42.19% 55.53% at 47.41% 62.85%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;

}

.home-slider-overlay {
  background: rgba(17, 17, 17, 0.35);
}

.text-error {
  color: $notification-error !important;
}

.color-red {
  color: $co-founder !important;
}

.color-success {
  color: $success !important;
}

.text-muted {
  color: $color-muted !important;
}

.page-text-page-background {
  background-color: $page-text-page-background;
}

.onboarding-tab-light {
  background-color: $light-white-tab;
}

.onboarding-bg-gray {
  background-color: $onboarding-input-bg-gray;
}
