.custom-slide-dialog {
    animation: slide-down 0.3s ease-out;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  

  .custom-slide-dialog.closing {
    animation: slide-up 0.3s ease-in forwards;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-20%);
      opacity: 0;
    }
  }
  