.mmb-separator {
  width: $spacer * 15;
  border-bottom: 3px solid $secondary-300;
}

// .vertical-separator {
//   border-left: 1px solid $color-muted;
//   height: $spacer * 8;
//   width: 2px;
// }

.horizontal-separator {
  border-bottom: 1px solid $color-muted;
  opacity: 0.5;
}

.separator-line-inline {
  min-width: 20px;
  margin-bottom: 3px;
  margin-right: 5px;
  display: inline-block;
  height: 1px;
  border: 1px solid;
}