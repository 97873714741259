body{
    @apply mmb-bg-theme_grey-100;
    @apply mmb-caption-2;
}

.mmb-responsive-wrapper{
    @apply mmb-max-w-container mmb-mx-auto mmb-my-0 mmb-w-full md:mmb-px-10 mmb-px-5;
}

a{
    text-decoration: none !important;
}