.mmb-cms {
    strong {
        @apply mmb-body-2 mmb-font-bold;
    }

    p, ol, ul, li, span, label {
        @apply mmb-body-1;
    }

    p{
        @apply mmb-mb-3;
    }

    h1 {
        @apply mmb-display-1;
    }

    h2 {
        @apply mmb-headline;
    }
    
    h3 {
        @apply mmb-subheading-1;
    }

    a { 
        @apply mmb-body-1;
    }

    ol, ul {
        @apply mmb-py-0 mmb-px-5;
        @apply mmb-flex mmb-flex-col mmb-gap-2;
        li{
            @apply before:mmb-content-[''] before:mmb-mt-2 before:-mmb-ml-5 before:mmb-absolute before:mmb-w-2 before:mmb-h-2 before:mmb-bg-bullet-point;
        }
    }
}