// .mmb-nav-tabs {
//     @extend .mmb-border-bottom-none;

//     .mmb-nav-item {
//         line-height: 1.5;
//         color: $black-100;
//     }
// }

.mmb-tab-menu--secondary {
    @extend .mmb-border-bottom-secondary;
}

.mmb-tab-menu--active {
    border-bottom: $spacer * 1 - 1 solid $secondary-300;

    i {
        color: $secondary-300;
    }
}


// .nav-item-project {
//     border-bottom: $spacer * 1 - 1 solid transparent;
//     &.mmb-tab-menu--active{
//         border-bottom: $spacer * 1 - 1 solid $secondary-300;
//     }
      
// .mmb-onbording-rounded-tabs {
//     width: $spacer * 3;
//     height: $spacer * 3;
//     border: 2px solid $gray-600;
//     @extend .border-radius-md;

//     &.active {
//         border: 2px solid $black;
//     }

//     &.completed {
//         width: $spacer * 1.5;
//         height: $spacer * 1.5;
//         background-color: $black;
//         border: none;
//     }
// }
// }