label{
  @apply mmb-caption-2 mmb-mb-3;
}

.mmb-form-control-lg {
    @apply mmb-mb-3;
    .mmb-form-control {
        @apply mmb-caption-2 mmb-w-full mmb-rounded-xs mmb-p-4;
        @apply mmb-border-2 mmb-border-theme_black-825;
      &:not(textarea) {
        @apply mmb-h-14;
      }

      &:disabled{
        background-color: #f6f6f6 !important;
      }

      &:not(:placeholder-shown) {
        @apply mmb-bg-onboarding_input mmb-text-theme_black-900 mmb-outline-none;
      }
  
      &:focus {
        @apply mmb-border-2 mmb-border-primary-600 mmb-bg-white mmb-text-theme_black-900 mmb-outline-none;
      }
  
      &::placeholder {
        @apply mmb-text-theme_black-900;
      }
  
      &.text-area {
        @apply mmb-h-60;
      }

      & input[formcontrolname="countryRegion"] {
        @apply mmb-bg-transparent;
      }

      &.ng-invalid.ng-touched{
        @apply mmb-border-theme_red-600 mmb-border-1;
      }
  
    }

    .mmb-form-control:disabled{
        @apply mmb-pointer-events-none mmb-text-theme_grey-400;
        @apply mmb-border-1 mmb-border-theme_grey-400;

        &::placeholder {
            @apply mmb-text-theme_grey-400
        }
        
        input {
            @apply mmb-bg-transparent;
        }
    }
}

.mmb-form-control-md{
  @apply mmb-w-full mmb-rounded-xs mmb-h-12 mmb-text-sm;
  @apply mmb-border-1 mmb-border-theme_light-300;
  @apply mmb-py-0 mmb-px-4;
  @apply hover:mmb-border-theme_black-150;
  @apply focus:mmb-border-primary-600 focus:mmb-bg-white focus:mmb-shadow-md focus:mmb-shadow-theme_light-250;
  @apply focus:mmb-outline-none;
  &.ng-touched.ng-invalid{
    @apply mmb-border-theme_red-600;
  }

}

.mmb-textarea{
  @apply mmb-w-full mmb-rounded-xs mmb-border-1 mmb-border-theme_light-300 mmb-text-sm;
  @apply mmb-py-3 mmb-px-4 mmb-h-24 mmb-resize-none;
  @apply hover:mmb-border-theme_black-150;
  @apply focus:mmb-border-primary-600 focus:mmb-bg-white focus:mmb-shadow-md focus:mmb-shadow-theme_light-250 focus:mmb-outline-none;

  &.ng-touched.ng-invalid{
    @apply mmb-border-theme_red-600;
  }
}


.mmb-dropdown-md {
  @apply mmb-w-full mmb-h-12 mmb-rounded-xs mmb-border-1 mmb-border-white mmb-shadow-none mmb-caption-2;
  @apply focus:mmb-border-primary-600 focus:mmb-bg-white focus:mmb-shadow-lg focus:mmb-outline-none;
}

.ngx-otp-input.ng-touched.ng-invalid{
  @apply mmb-border-theme_red-600;
}


.mmb-switch {
  @apply mmb-flex mmb-items-center mmb-mx-3 mmb-my-0;
}
.mmb-switch input[type=checkbox] {
  @apply mmb-w-0 mmb-h-0 mmb-invisible;
}
.mmb-switch input[type=checkbox]:checked + label {
  @apply mmb-bg-primary-600;
}
.mmb-switch input[type=checkbox]:checked + label::after {
  left: calc(100% - 4px);
  @apply -mmb-translate-x-full;
}
.mmb-switch label {
  @apply mmb-cursor-pointer mmb-w-9 mmb-h-6 mmb-bg-theme_black-200 mmb-block mmb-rounded-full mmb-relative;
}
.mmb-switch label::after {
  @apply mmb-content-[''] mmb-absolute mmb-top-1 mmb-left-1 mmb-w-4 mmb-h-4 mmb-bg-white mmb-rounded-full mmb-duration-300;
}