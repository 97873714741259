.mmb-line-height-none {
    line-height: $spacer * 0;
}
.mmb-line-height-xs {
    line-height: $spacer * 4 !important;
}
.mmb-line-height-sm {
    line-height: $spacer * 6 !important;
}
.mmb-line-height-md {
    line-height: $spacer * 8;
}
.mmb-line-height-lg {
    line-height: $spacer * 10;
}
// .mmb-text-in-2-line{
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     display: -webkit-box;
//     overflow: hidden;
// }

.line-height-md {
    line-height: 1.5 !important;
}