.card{
    @extend .mmb-body-1, .mb-6, .border-radius-sm, .p-4;
    border: $spacer * 0.25 solid $white;
    background-color: $white;

    // .card-title{
    //     @extend .mmb-subheading-2, .mmb-border-bottom;
    //     margin-bottom: 0;
    //     line-height: $spacer * 12;
        
    // }
    
    // .bullet-tag{
    //     @extend .border-radius-md;
    //     border: $spacer * 0.25 solid $other;
    //     display: inline-block;
    //     vertical-align: middle;
    // }

    li{
        display: flex;
        justify-content: space-between;
        line-height: $spacer * 12;
        a{
            color: $secondary-300;
        }
    }
    .accordion{
        height: $spacer * 5;
        width: $spacer * 5;
    }
}

// .card-bookmarker {
//     &:hover {
//         background-color: $secondary-color--hover;
//         i {
//             color: $primary !important;
//         }
//     }
//     &:active {
//         i {
//             color: #000 !important;
//         }
//     }
//     &.green {
//         background-color: $success-light;
//     }
// }