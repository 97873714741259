/**
mmb-primary-button, mmb-primary-button--light, mmb-primary-button--dark, mmb-primary-button--disabled
mmb-secondary-button, mmb-secondary-button--light, mmb-secondary-button--dark, mmb-secondary-button--disabled
mmb-tertiary-button,
mmb-link-button,
*/

// Primary buttons
.mmb-primary-button {
    background: $secondary-300;
    border: 1px solid $white;
    color: $white;
    padding: $spacer * 2 $spacer * 6;
    border-radius: 1.5rem;
    cursor: pointer;
    vertical-align: middle;
    min-height: 40px;

    // line-height: 1;
    &:hover {
        opacity: 0.85;
    }

    &.stroked-button {
        background: transparent;
        border: 1px solid $secondary-300;
        color: $secondary-300;
    }

    &.flat-button {
        background: $white;
        border: none;
        color: $primary;
    }

    &.disabled {
        opacity: 0.5;
    }
}

.mmb-primary-button:disabled{
    background-color: black;
    pointer-events: none;
    opacity: 0.5;
}

.mmb-primary-button--dark {
    /*
    replace this with the following::

    mmb-bg-black mmb-py-2 mmb-px-4 mmb-rounded-full mmb-text-white mmb-flex 
    mmb-justify-center mmb-items-center mmb-cursor-pointer mmb-border-1 mmb-border-black 
    disabled:mmb-pointer-events-none disabled:mmb-opacity-50 
    hover:mmb-bg-theme_black-500 hover:mmb-border-theme_black-500
    active:mmb-bg-theme_black-300 active:mmb-border-theme_black-300 active:mmb-outline-none
    focus:mmb-bg-theme_black-300 focus:mmb-border-theme_black-300 focus:mmb-outline-none
    mmb-transition-colors mmb-ease-in-out mmb-duration-200
                        
    */
    background-color: $text-black;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $text-black;

    &:disabled{
        pointer-events: none;
        opacity: 0.5;
    }

    &:hover {
        background-color: $black_bg_hover;
        border: 1px solid $black_bg_hover;
    }

    &:active, &:focus {
        background-color: $black_bg_active;
        border: 1px solid $black_bg_active;
        outline: none;
    }
}

/* Secondary buttons */
.mmb-secondary-button {
    background: $buttons-secondary;
    color: $secondary-300;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    border: none;

    &.btn-full-width {
        @extend .w-100;
    }

    &:hover {
        background: $buttons-secondary;
        color: $secondary-300;
    }
}

.mmb-secondary-button--lightest {
    background-color: $white-bg-fff;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $black-100;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $white-bg-fff;

    &:hover {
        border: 1px solid $secondary-300;
        background-color: $secondary-300;
        color: $white;
    }
}


.mmb-secondary-button--light {
    background-color: $white-bg-fff;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $secondary-300;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $secondary-300;

    &:hover {
        border: 1px solid $secondary-300;
        background-color: $secondary-300;
        color: $white;
    }

    &.disabled {
        pointer-events: none;
        color: $other !important;
        border: $spacer * 0.25 solid $other !important;
        box-shadow: none !important;
    }
}

.mmb-primary-button--disabled,
.mmb-secondary-button--disabled {
    background-color: $buttons-disabled;
    outline: none;
    border-radius: $spacer * 25;
    color: $text-gray-disabled;
    border: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    pointer-events: none;

    /*
    replace with the following classes
    mmb-bg-theme_light-50 mmb-outline-none mmb-rounded-full mmb-text-theme_light-150 mmb-border-none mmb-flex mmb-justify-center mmb-items-center mmb-pointer-events-none
    */
}

.mmb-secondary-button--dark {
    background-color: $secondary-300;
    color: $white;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $secondary-300;

    &.success {
        background-color: $opportunity-investment;
        border: 1px solid $opportunity-investment;
    }

    &.btn-full-width {
        @extend .w-100;
    }

    &:hover {
        border: 1px solid $secondary-300;
        background-color: $secondary-300;
        color: $white;
    }

    &.is-disabled {
        background-color: $buttons-disabled;
        pointer-events: none;
        color: $text-gray-disabled;
        border: none;
    }
}


// Tertiary buttons
.mmb-tertiary-button {
    background-color: $secondary-300;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 4;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $secondary-300;

    &:hover {
        border: 1px solid $secondary-300;
        background-color: $white-bg-fff;
        color: $secondary-300;
    }
}

.mmb-tertiary-button--dark {
    background-color: $white-bg-fff;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $text-black;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $text-black;

    &:hover {
        border: 1px solid $text-black;
        background-color: $text-black;
        color: $white;
    }
}



.mmb-success-button {
    background: $success;
    border: 1px solid $white;
    color: $white;
    padding: $spacer * 2 $spacer * 6;
    border-radius: 1.5rem;
    min-height: 40px;
    line-height: 1;
    outline: none;

    &:hover {
        opacity: 0.85;
    }

    &:active {
        border: none;
    }

    &.stroked-button {
        background: transparent;
        border: 1px solid $success;
        color: $success;
    }

    &.flat-button {
        background: $white;
        border: none;
        color: $success;
    }

    &.disabled {
        pointer-events: none;
    }
}

.mmb-icon-button--tertiary {
    width: $spacer *5;
    height: $spacer * 5;
    background-color: $secondary-1000;
    text-align: center;
    line-height: $spacer * 5;
    border-radius: $spacer * 25;
}

.mmb-tertiary-button--lightest {
    background-color: transparent;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $page-text-white;
    border: 1px solid $page-text-white;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    &.dark-bg {
        padding: $spacer * 4 $spacer * 4;
        &:hover {
            background-color: $secondary_dark_hover;
            border-color: transparent;
        }
        &:active, &:focus {
            background-color: $secondary_dark_hover !important;
            border: 1px solid $white !important;
            outline: none !important;
        }
    }

    &:hover {
        border: 1px solid $secondary-300;
        background-color: $secondary-300;
        color: $white;
    }
}

.mmb-tertiary-button--disabled {
    background-color: $bg-gray-disabled;
    padding: $spacer * 2 $spacer * 4;
    border-radius: $spacer * 25;
    color: $text-gray-disabled;
    display: inline-block;
    border: 1px solid $page-text-white;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid $bg-gray-disabled;
    pointer-events: none;
}

.mmb-icon-button {
    width: $spacer * 8;
    height: $spacer * 8;
    background-color: $white-bg-fff;
    border-radius: $spacer * 25;
    box-shadow: 0 0 $spacer * 0.5 0 $shadow-color;
    border: none;

    i {
        vertical-align: middle;
    }

    &.mmb-icon-button--no-wrapper {
        box-shadow: none;
        font-size: $spacer * 7.5;
        margin-top: - $spacer * 2;

        i {
            vertical-align: top;
        }
    }
}

// Rounded icon button

.mmb-rounded-icon-button {
    border-radius: $spacer * 25;
    width: $spacer * 8;
    max-width: $spacer * 8;
    height: $spacer * 8;
    line-height: $spacer * 8;
    text-align: center;
    padding: 0;
    cursor: pointer;

    i {
        vertical-align: middle;
    }
}

// TODO: The button disabled should not be generic. Rather each button has its own disabled
.mmb-button--disabled {
    pointer-events: none;
    background-color: $bg-gray-disabled !important;
    border: none !important;
    color: $text-gray-disabled !important;
}

.mmb-button--disabled-login {
    pointer-events: none;
}

.mmb-onboarding-footer {
    .mmb-button--disabled {
        pointer-events: none;
        background-color: rgb(16, 111, 255, 0.25) !important;
        border: none !important;
        color: $text-gray-disabled !important;
    }
}

.pointer-events-none {
    pointer-events: none;
}

// Rounded icon button
.mmb-rounded-icon-button--xs {
    @extend .border-radius-xl;
    width: $spacer * 8;
    height: $spacer * 8;
    line-height: $spacer * 6;
    color: $text-black;
    background-color: $blue-dark;
    border: $spacer * 0.25 solid $blue-dark;
    text-align: center;
    @extend .mmb-caption;

    &.step-number-small--active,
    &.step-number--active {
        width: $spacer * 8;
        height: $spacer * 8;
        line-height: $spacer * 8;
        background-color: transparent;
        color: $white;
        border: $spacer * 0.25 solid $secondary-300;
        @extend .mmb-caption;
    }

    &.step-number-small--done,
    &.step-number--done {
        background-color: $primary;
        color: $page-text-white;
        border: $spacer * 0.25 solid $primary;
    }

    &.step-number--done-sm {
        padding: 0;
    }
}

.sm-rounded-btn {
    .step-number--active-sm {
        border: $spacer * 0.25 solid $secondary-300;
        width: $spacer * 6;
        height: $spacer * 6;
        border-radius: $spacer * 6;
        @extend .mmb-caption;
    }

    .horizontal-separator {
        padding-right: 3px;
        padding-left: 3px;
    }
}

.mmb-rounded-icon-button--sm {
    @extend .border-radius-xl, .mmb-subheading-2;
    width: $spacer * 10;
    height: $spacer * 10;
    line-height: $spacer * 10;
    color: $text-black;
    text-align: center;
}

.mmb-rounded-icon-button--md {
    @extend .border-radius-xl;
    width: $spacer * 15;
    height: $spacer * 15;
    color: $text-black;
    border: none;
    text-align: center;
}

.mmb-rounded-voting-button {
    box-shadow: 0 4px 6px 0 $light-gray;
    width: 32px;
    height: 32px;
    background-color: $white-bg-fff;

    &.upvote {
        color: $success;

        &:hover {
            border: $spacer * 0.25 solid $success !important;
            background-color: $success-light !important;
        }

        &:active {
            background-color: $success-light !important;
            border: none !important;
            box-shadow: 0 2px 2px 0 $light-gray;
        }

        &.disabled {
            pointer-events: none;
            color: $other !important;
            border: $spacer * 0.25 solid $other !important;
            box-shadow: none !important;
        }

        i {
            color: $success;

            &.disabled {
                pointer-events: none;
                color: $other !important;
            }
        }
    }

    &.downvote {
        color: $notification-error;

        &:hover {
            border: $spacer * 0.25 solid $notification-error !important;
            background-color: $error-bg !important;
        }

        &:active {
            border: none !important;
            background-color: $error-bg !important;
            box-shadow: 0 2px 2px 0 $light-gray;
        }

        &.disabled {
            pointer-events: none;
            color: $other !important;
            border: $spacer * 0.25 solid $other !important;
            box-shadow: none !important;
        }

        i {
            color: $notification-error;

            &.disabled {
                pointer-events: none;
                color: $other !important;
            }
        }
    }
}

.mmb-btn {
    @extend .mmb-button;
    @extend .border-radius-lg;
    padding: $spacer*2 $spacer*5 $spacer*2.5;
    line-height: $spacer * 6;

    &.mmb-btn-full {
        @extend .w-100;
    }

    &.mmb-btn-md {
        font-size: $spacer * 4;
    }

    &.mmb-btn-primary {
        background: $buttons-primary;
        border: 1px solid $buttons-primary;
        color: $white;

        &:hover {
            background: $primary-color--hover;
            border: 1px solid $primary-color--hover;
        }

        &:active {
            background: $primary-color--active;
            border: 1px solid $primary-color--active;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $buttons-primary;
            border: 1px solid $buttons-primary;
            @extend .mmb-outline-none;
        }

        &.mmb-primary-btn-slider {
            background: $investment;
            border: 1px solid $investment;

            &:hover {
                @extend .opacity-light;
            }
        }

        &.mmb-primary-btn-light {
            background: $white;
            border: 1px solid $white;
            color: $black-100;

            &:hover {
                @extend .opacity-light;
            }
        }
    }

    &.mmb-btn-secondary {
        /*
        should be replaced with the following classes:
        
        mmb-secondary-btn
        */
        background: $buttons-secondary;
        border: 1px solid $buttons-secondary;
        color: $buttons-primary;

        &:hover {
            background: $secondary-color--hover;
            border: 1px solid $secondary-color--hover;
        }

        &:active {
            background: $secondary-color--active;
            border: 1px solid $secondary-color--active;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $buttons-secondary;
            border: 1px solid $buttons-primary;
            @extend .mmb-outline-none;
        }

        &.mmb-active-btn {
            background-color: $primary-900 !important;
            color: $white !important;
        }
    }

    &.mmb-btn-tertiary {
        /*
        replace btn btn-tertiary with the following
        mmb-tertiary-btn
        */
        background-color: transparent;
        border: 1px solid $buttons-primary;
        color: $buttons-primary;

        &.light-text {
            color: $page-text-white;

            &:hover {
                color: $buttons-primary !important;
            }

            &:active,
            &:focus {
                color: $buttons-primary !important;
            }
        }

        &:hover {
            background: $buttons-secondary;
            border: 1px solid $buttons-secondary;
        }

        &:active {
            background: $secondary-color--hover;
            border: 1px solid $secondary-color--hover;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $buttons-secondary;
            border: 1px solid $buttons-primary;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-btn-tertiary--light {
        background-color: transparent;
        border: 1px solid $page-text-white;
        color: $page-text-white;

        &:hover {
            background: $buttons-primary;
            border: 1px solid $buttons-primary;
        }

        &:active {
            background: $buttons-primary;
            border: 1px solid $buttons-primary;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $buttons-primary;
            border: 1px solid $buttons-primary;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-btn-success {
        background: $success;
        border: 1px solid $success;
        color: $white;

        &:hover {
            opacity: 0.85;
        }

        &:active {
            background: $success;
            border: 1px solid $success;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $success;
            border: 1px solid $success;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-btn-warn-dark {
        background: $opportunity-expert-advise;
        border: 1px solid $opportunity-expert-advise;
        color: $white;

        &:hover {
            opacity: 0.85;
        }

        &:active {
            background: $opportunity-expert-advise;
            border: 1px solid $opportunity-expert-advise;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $opportunity-expert-advise;
            border: 1px solid $opportunity-expert-advise;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-error-button {
        background: $lightred;
        border: 1px solid $white;
        color: $white;

        &:hover {
            @extend .opacity-light;
        }

        &:active {
            @extend .opacity-light;
            border: 1px solid $white;
            @extend .mmb-outline-none;
        }

        &:focus {
            @extend .opacity-light;
            border: 1px solid $white;
            @extend .mmb-outline-none;
        }

        &.stroked-button {
            background: transparent;
            border: 1px solid $success;
            color: $success;
        }

        &.flat-button {
            background: $white;
            border: none;
            color: $success;
        }

        &.disabled {
            pointer-events: none;
        }
    }

    .mmb-primary-button--dark {
        background-color: $text-black;
        color: $white;
        border: 1px solid $text-black;

        &:hover {
            border: 1px solid $text-black;
            background-color: $white;
            color: $text-black;
        }

        &:active {
            @extend .mmb-border-none;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-button-dark--border {
        background-color: $white;
        color: $text-black;
        border: 1px solid $text-black;

        &:hover {
            background-color: $text-black;
            color: $white;
        }

        &:active {
            @extend .mmb-border-none;
            @extend .mmb-outline-none;
        }
    }

    &.mmb-special-button {
        background: $special-btn;
        border: 1px solid $special-btn;
        color: $special-btn-text;

        &:hover {
            opacity: 0.85;
        }

        &:active {
            background: $special-btn;
            border: 1px solid $special-btn;
            @extend .mmb-outline-none;
        }

        &:focus {
            background: $special-btn;
            border: 1px solid $special-btn;
            @extend .mmb-outline-none;
        }
    }

    &[disabled] {
        background-color: transparent !important;
        border: 1px solid $text-gray-disabled !important;
        color: $text-gray-disabled !important;

        &:hover {
            background-color: transparent !important;
            border: 1px solid $text-gray-disabled !important;
            color: $text-gray-disabled !important;
        }
    }
}

.is-button-disabled {
    pointer-events: none;
}

.remove-pill {
    &:hover {
        .remove-icon {
            color: $notification-error;
        }
    }
}

.mmb-link-button--primary {
    color: $primary;
    border-bottom: 1px solid $white;
    &:hover {
        cursor: pointer;
    }
}