.top-nav-bar {
    ul {
        li {
            a {
                @apply mmb-text-white;

                &:hover {
                    @apply mmb-text-white_opacity-75;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        @apply mmb-bg-primary-600;
                        border-radius: 2px 2px 0 0;
                    }
                }
            }

            ul {
                visibility: hidden;

                li {
                    a {
                        @apply mmb-text-theme_black-100;
                        width: 100%;

                        &.active {
                            @apply mmb-text-white;

                            .bullet-point {
                                @apply mmb-bg-primary-600;
                            }
                        }
                    }
                }
            }

            &:hover {
                ul {
                    visibility: visible;

                    li {
                        a {
                            .bullet-point {
                                background-color: transparent;
                            }
                        }

                        a:hover {
                            @apply mmb-text-white;

                            &::before {
                                display: none;
                            }

                            .bullet-point {
                                @apply mmb-bg-primary-600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.nav-item:hover {

    a,
    i {
        @apply mmb-text-primary-600;
    }
}

// .user-placeholder {
//     &:hover {
//         @extend .bg-primary
//     }
// }


@media(max-width:1100px) {
    .right-menu {
        display: none !important;
    }

    .mobile-menu {
        display: flex !important;

        ul {
            li {
                &:hover {
                    @apply mmb-bg-white_opacity-6;

                    .ri-arrow-right-line {
                        @apply mmb-text-white;
                    }
                }
            }
        }
    }
}


.t-navbar-height {
    top: 80px;
}