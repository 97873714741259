.mmb-border-none {
  border: none !important;
}
.mmb-border-bottom-none {
  border-bottom: none;
}
.mmb-border-left-none {
  border-left: none;
}
.mmb-border {
  border: solid 1px $light-gray;
}
.mmb-border-top {
  border-top: solid 1px $light-gray;
}
.mmb-border-right {
  border-right: solid 1px $light-gray;
}
.mmb-border-left {
  border-left: solid 1px $light-gray;
}
.mmb-border-bottom {
  // replace with the following mmb-border-b-1 mmb-border-theme_light-250
  border-bottom: solid 1px $light-gray;
}
.mmb-border-transparent{
  border: solid 1px $transparent;
}
.mmb-border--lighter  {
  border: $spacer * 0.25 solid $light-gray;
}
.mmb-border--light  {
  border: $spacer * 0.5 solid $light-gray;
}
.mmb-border--error  {
  border: $spacer * 0.25 solid $notification-error;
}
.mmb-border--dark  {
  border: $spacer * 0.25 solid $light-gray-dark;
}
.mmb-border-success {
  border: $spacer * 0.25 solid $success !important;
}
.mmb-border-success--thick {
  border: $spacer * 0.5 solid $success !important;
}
.mmb-border-left--dark  {
  border-left: $spacer * 0.25 solid $light-gray-dark;
}
.mmb-border-left-secondary {
    border-left: $spacer * 1 solid $secondary-300;
}
.mmb-border-badge {
  border: $spacer * 0.25 solid $other;
}
.mmb-border-primary-300 {
  border: $spacer * 0.25 solid $primary-300;
}
.mmb-border-left-secondary--lg{
  @extend .mmb-border-left-secondary;
  left: 10px;
  height: $spacer * 14;
  position: absolute;
  top: -40px;
}
.mmb-onboarding-top-dashed-border {
  border-top: $spacer * 0.25 dashed $onboarding-input-gray;
}
.mmb-onboarding-top-dashed-border--light {
  border-top: $spacer * 0.25 dashed $modal-header-bg;
}
.mmb-onbording-border-sm {
  border: $spacer * 0.5 solid $white-opacity-onbording;
}

.mmb-onbording-dashed-xs {
  border: $spacer * 0.25 dashed $gray-600;
}

.mmb-onboarding-gray-border {
  border: $spacer * 0.5 solid $onboarding-border-gray
}
.mmb-onboarding-black-border {
  border: $spacer * 0.5 solid $text-black;
}

.mmb-border-primary  {
  border: $spacer * 0.5 solid $buttons-primary;
}
.mmb-border-transparent-md  {
  border: $spacer * 0.5 solid transparent;
}
.mmb-border-secondary  {
    border: $spacer * 0.25 solid $secondary-300;
}
.mmb-border-highlight {
  border: $spacer * 0.25 solid $bg-yellow;
}
.mmb-border-bottom-secondary {
  border-bottom: $spacer solid $secondary-300;
}
.mmb-border-bottom-secondary--light {
  border-bottom: $spacer * 0.5 solid $secondary-300;
}

// .mmb-border-bottom-secondary--lg {
//   left: 10px;
//   width: $spacer * 8;
//   height: $spacer / 2;
//   background-color: $secondary-300;
//   margin: 0 auto;
// }
.mmb-border-lightest  {
  border: $spacer * 1 solid $page-text-white;
}
.mmb-border-top--lightest {
  border-top: $spacer * 0.75 solid $page-text-white;
}
.mmb-border--lightest-lg {
  border: $spacer * 2 solid $page-text-white;
}
// .mmb-border-error {
//   border:1px solid $notification-error !important;
// }

.mmb-border-white-sm {
  border: $spacer * 0.25 solid $white !important;
}

// .mmb-border-white-md {
//   border: $spacer * 0.50 solid $white;
// }

.mmb-border-dark-sm {
  border: $spacer * 0.25 solid $black;
}

.mmb-border-dark-md {
  border: $spacer * 0.50 solid $primary-900;
}

.mmb-border-active-primary {
  border: $spacer * 0.25 solid $primary;
}

.mmb-border-active-primary-button-border {
  border: $spacer * 0.25 solid $buttons-primary;
}

.mmb-border-active-secondary {
  border: $spacer * 0.25 solid $secondary-1000;
} 

.mmb-no-border-hover {
  &:hover {
    border-color: $primary !important;
  }
}

.mmb-border-rounded {
  border-radius: 50%;
  width: $spacer * 8;
  min-width: $spacer * 8;
  height: $spacer * 8;
  min-height: $spacer * 8;

  &.xs {
    width: $spacer * 6;
    min-width: $spacer * 6;
    height: $spacer * 6;
    min-height: $spacer * 6;
  }

  &.lg {
    width: $spacer * 12;
    min-width: $spacer * 12;
    height: $spacer * 12;
    min-height: $spacer * 12;
  }

  &.auto {
    width: auto;
    height: auto;
  }
}

.mmb-outline-none {
  outline: none !important;
}

.mmb-border-top--lightest---dash  {
  border-top: $spacer * 0.25 dashed $white;
}
.mmb-border-top-dark  {
  border-top: $spacer * 0.5 solid $black;
}

.mmb-border-top--light---dash  {
  border-top: $spacer * 0.25 dashed $light-border;
}

.mmb-border-primary--sm {
  border: $spacer * 0.5 solid $primary;
}

.mmb-border-secondary--sm {
  border: $spacer * 0.5 solid $buttons-secondary;
}

.mmb-border-secondary--xs {
  border: $spacer * 0.25 solid $buttons-secondary;
}

.mmb-border-bottom--lightest---dash  {
  border-bottom: $spacer * 0.25 dashed $other;
}

.mmb-border-bottom--lightest---solid  {
  border-bottom: $spacer * 0.25 solid $other;
}

.mmb-border-dark {
  border: 1px solid $black-100;
}

// .mmb-border-darkest {
//   border-color: $black-100;
// }

hr {
  border-top: none;
}

// .mmb-border-top--white-overlay {
//   border-top: 1px solid $light-color;
// }

.mmb-title-border-primary {
  border-left: 3px solid $primary;
  padding-left: 30px;
}

.mmb-title-border-success {
  border-left: 3px solid $success;
  padding-left: 30px;
}

.mmb-secondary-onbording-border-md {
  border: 2px solid $border-onboarding-secondary;
}
