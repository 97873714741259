.background-position-right {
    background-position: right;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-sticky {
    position: sticky;
}

.mmb-top-0px {
    top: 0;
}

.mmb-top-n20px {
    top: -20px;
}

.mmb-top-n30px {
    top: -30px;
}

.mmb-top-2 {
    top: $spacer * 2;
}

.mmb-top-3 {
    top: $spacer * 3;
}

.mmb-top-4 {
    top: $spacer * 4;
}

.mmb-left-4 {
    left: $spacer * 4;
}

.mmb-top-20px {
    top: $spacer * 5;
}

.mmb-top-n24px {
    top: -$spacer * 6;
}

.mmb-center--y {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// .mmb-top-50px {
//     top: 50%;
// }
.mmb-top-30 {
    top: 30%;
}

.mmb-top-n40px {
    top: -40px;
}

.mmb-top-n80px {
    top: -80px;
}

.mmb-bottom-0,
.mmb-bottom-0px {
    bottom: 0;
}

.mmb-bottom-n30px {
    bottom: -30px;
}

.mmb-bottom-n80px {
    bottom: -80px;
}

.mmb-bottom-n21px {
    bottom: -21px;
}

.mmb-bottom-5px {
    bottom: 5px;
}

.mmb-bottom-10px {
    bottom: 10px;
}

.mmb-bottom-15px {
    bottom: 15px;
}

.mmb-bottom-20px {
    bottom: 24px;
}

.mmb-bottom-24px {
    bottom: 24px;
}

.mmb-bottom-30px {
    bottom: 30px;
}

.mmb-bottom-40px {
    bottom: 40px;
}

.mmb-top-n40px {
    top: -40px;
}

.mmb-left-20px {
    left: 20px;
}

.mmb-left-n200px {
    left: -200px;
}

.mmb-left-n240px {
    left: -240px;
}

.mmb-left-n360px {
    left: -360px;
}

.mmb-left-24px {
    left: 24px;
}

.mmb-left-90 {
    left: 90%;
}

.mmb-left-80 {
    left: 80%;
}

.mmb-left-32px {
    left: 32px;
}

.mmb-left-n20px {
    left: -20px;
}

.mmb-left-n40px {
    left: -40px;
}

.mmb-left-16px {
    left: 16px;
}

.mmb-left-10px {
    left: 10px;
}

.mmb-left-50px {
    left: 50px;
}

.mmb-bottom-50px {
    bottom: 50px;
}

.mmb-left-0px {
    left: 0;
}

.mmb-right-0px {
    right: 0;
}

.mmb-right-2 {
    right: $spacer * 2;
}

.mmb-right-4 {
    right: $spacer * 4;
}

.mmb-right-15px {
    right: 15px;
}

.mmb-right-24px {
    right: 24px;
}

.mmb-right-45px {
    right: 45px;
}

.mmb-translate-y-8px{
    transform: translateY(8px);
}

.absolute-center {
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-center-right{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -10px);
}

.absolute-bottom {
    bottom: 0;

}
