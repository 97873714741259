.grow-block{
    .grow-item {
        height: 100%;
        .grow-subitem:hover i {
            transform: translateY(10px);
            -webkit-transform: translateY(10px);
            -ms-transform: translateY(10px);
            -moz-transform: translateY(10px);
            -o-transform: translateY(10px);
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }

        .grow-subitem {
            border-radius: 6px;
            box-shadow: 0 0 18px 0 rgb(185 190 202 / 35%);
            padding: 24px 44px;
            background-color: $white-bg-fff;
            height: 100%;
            text-align: center;
            i{
            margin-bottom: 24px;
            display: block;
            color: $investment;
            font-size: 35px;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            }
        }
    }
}
.enquiry-block {
    // background-color: $dark-bg;
    .enquiry-inner {
        .enquiry-item{
            i {
                margin-bottom: 24px;
                display: block;
                font-size: 25px;
             }
            .enquiry-subitem{
                border-left: 1px solid $white-bg-fff !important;
            }
        }
    }
    .enquiry-form {
        border-radius: 6px;
        box-shadow: 0 0 18px 0 rgba(185, 190, 202, 0.35);
        padding: 24px 24px;
        background-color: $white-bg-fff;
        height: 100%;
        i{
            font-size: 18px !important;
            line-height: normal;
        }
        a{
           color: $buttons-primary;
        }
        select{
            color: $btn-color--secondary;
        }
        .mat-expansion-panel-body{
            padding: 0 !important;
        }
    }
    .equiry-form-file{
        border: 3px dashed $other;
        border-radius: 15px;
        background: $light-blue-100;
        i {
            font-size: 25px !important;
        }
    }
}
.talent-block {
    .talent-inner{
        .talent-item {
            .talent-subitem {
                box-shadow: 0 12px 18px 0 rgb(185 190 202 / 25%);
            }
        }
    }
}
.advertising-block {
    background-image: linear-gradient(to bottom, #363c44, #2a2f34);
    .advertising-inner {
        .border-right {
            border-right: 1px solid rgb(185, 190, 202, 0.5) !important;
        }  
    }
}
.consulting-block {
    .consulting-inner {
        .border-right {
            border-right: solid 1px rgba(185, 190, 202, 0.5) !important;
        }
    }
}
.collaboration-block {
    background-image: linear-gradient(to bottom, #363c44, #2a2f34);
}
.oursupport-block {
    .oursupport-inner {
        .oursupport-item {
            .oursupport-subitem {
                box-shadow: 0 12px 18px 0 rgba(185, 190, 202, 0.25);
                .oursupport-img {
                    max-height: 184px;
                }
            }
        }
    }
}
.join-talent {
    .join-talent-inner {
        .collaboration-item {
            .collaboration-sub-item {
                min-height: 415px;
                .img {
                    left: 0;
                    top: 0;
                    margin-top: -($spacer * 8);
                    margin-left: ($spacer * 3);
                    box-shadow: 0 4px 8px 0 rgba(185, 190, 202, 0.35);
                }
            }
        }
    }
}
