.modal-dialog.modal-sm {
    // width: 100%;
    // max-width: $spacer * 100;
    @apply mmb-w-full mmb-max-w-[400px];
}

.modal-backdrop.show {
    opacity: unset !important;
    // background-color: $modal-back-drop !important;
    @apply mmb-bg-modal_backdrop;
}

@media(max-width:400px) {
    .modal-dialog.modal-sm {
        margin: 0 !important;
    }
}

ngb-modal-window {
    z-index: 1060 !important;
}

.modal-header-bg {
    // background-color: $modal-header-bg;
    @apply mmb-bg-theme_light-100;
}