.mmb-display-3{
    @apply md:mmb-font-helvetica-bold mmb-font-helvetica;
    @apply lg:mmb-text-4xl md:mmb-text-2xl mmb-text-lg;
    @apply lg:mmb-leading-lg mmb-leading-3xl;
    @apply mmb-tracking-normal;
}

.mmb-display-2{
    @apply md:mmb-font-helvetica-bold mmb-font-helvetica;
    @apply lg:mmb-text-6xl md:mmb-text-4xl mmb-text-2xl;
    @apply xl:mmb-leading-xl mmb-leading-lg;
    @apply mmb-tracking-normal;
}

.mmb-display-1 {
    @apply md:mmb-font-helvetica-bold mmb-font-helvetica;
    @apply xl:mmb-text-7xl lg:mmb-text-[56px] md:mmb-text-6xl mmb-text-5xl;
    @apply md:mmb-leading-sm mmb-leading-base;
    @apply mmb-tracking-normal;
}

.mmb-headline {
    @apply md:mmb-font-helvetica-bold mmb-font-helvetica;
    @apply lg:mmb-text-6xl md:mmb-text-5xl mmb-text-4xl;
    @apply lg:mmb-leading-xl mmb-leading-lg;
    @apply mmb-tracking-normal;
}

.mmb-title{
    @apply mmb-font-helvetica-bold;
    @apply xl:mmb-text-6xl mmb-text-2xl;
    @apply md:mmb-leading-xl mmb-leading-lg;
    @apply mmb-tracking-normal;
}

.mmb-subheading-1{
    @apply mmb-font-openSans-bold;
    @apply xl:mmb-text-2xl md:mmb-text-base mmb-text-xl mmb-text-base;
    @apply mmb-leading-2xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-light;
}

.mmb-subheading-2{
    @apply lg:mmb-font-openSans-light mmb-font-openSans;
    @apply xl:mmb-text-2xl md:mmb-text-xl mmb-text-base;
    @apply mmb-leading-2xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-semibold;
}

.mmb-subheading-3{
    @apply mmb-font-openSans-bold;
    @apply xl:mmb-text-2xl md:mmb-text-base mmb-text-xl mmb-text-base;
    @apply md:mmb-leading-3xl mmb-leading-2xl;
    @apply mmb-tracking-normal;
}

.mmb-body-1{
    @apply mmb-font-openSans;
    @apply lg:mmb-text-lg md:mmb-text-base mmb-text-sm;
    @apply mmb-leading-6xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-normal;
}

.mmb-body-2{
    @apply mmb-font-openSans-bold;
    @apply md:mmb-text-lg sm:mmb-text-base mmb-text-sm;
    @apply mmb-leading-6xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-bold;
}

.mmb-caption{
    @apply mmb-font-openSans;
    @apply lg:mmb-text-base mmb-text-sm;
    @apply md:mmb-leading-7xl mmb-leading-8xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-normal;
}

.mmb-caption-2,
.mmb-caption-3{
    @apply mmb-font-openSans;
    @apply mmb-leading-7xl;
    @apply mmb-tracking-normal;
    @apply mmb-font-normal;
}

.mmb-caption-2{
    @apply md:mmb-text-sm mmb-text-xs;
}

.mmb-caption-3{
    @apply mmb-text-xs;
}

.mmb-button{
    @apply mmb-font-openSans;
    @apply md:mmb-text-base sm:mmb-text-sm mmb-text-base;
    @apply mmb-leading-6xl;
    @apply mmb-tracking-normal;
}
