// $types: (
//     "co-founder": $co-founder,
//     "connection": $connection,
//     "expert-advice": $expert-advice,
//     "investment": $investment,
//     "mentor": $mentor,
//     "partnership": $partnership,
//     "volunteer": $volunteer,
//     "other": $other,
//     "all": $all,
// );

// @each $type,
// $color in $types {
//     [data-type="#{$type}"].filter-item.mmb-tab-active {
//         height: $spacer * 22;
//         @extend .mmb-button;
//         border-bottom: none;
//         background-color: $white;
//         color: $black-400;
//         &.active-mobile {
//             height: $spacer * 20;
//         }
//         &.filter-item--active {
//             background-color: $color;
//             color: $white;
//             border:1px solid $color;

//             i {
//                 @extend .text-white;
//             }
//         }

//         i {
//             color: $black-400;
//         }

//         &.opportunity-card-tag-wrap {
//             width: auto;
//             height: auto;
//             padding: $spacer/ 2 $spacer * 3;
//             display: inline-block;
//             @extend .mmb-caption;
//             font-size: 10px;
//             text-transform: uppercase;
//         }
//     }
//     [data-type="#{$type}"].icon-color {
//         g{
//             fill: $color;
//         }
//     }
// }

.all-item {
    background-color: $white;
    color: $black-400;
    height: $spacer * 20;
    @extend .mmb-border--dark;
    &.active {
        @extend .mmb-border-primary;
        background: $buttons-secondary;
    }
}