$small: 375px;
$medium: 768px;
$large: 992px;
$xlarge: 1200px;
$widescreen: 1400px;
$off-white: #f6f9ff; //replaced with theme_light-200
$min: min-width;
$max: max-width;
$slick-font-family: 'HelveticaNeue-Bold' !important;
$primary-brand-legacy-brand: #3498db;   //replaced with primary 500
// $primary-brand-contrast-section: #293b61;   //replaced with primary 900
$page-bg-light-contrast: #e7f1ff;   // replaced with theme_grey_300
$section-bg-light-grey: #f4f8ff;    //replaced with theme_grey_200
$page-text-grey: #666666;   //replaced with medium grey
$page-text-page-background: #f3f4f6;    //replaced with theme_grey_100
$page-text-white: #ffffff;  //no need for this variable
$buttons-secondary: #e8f1ff; //replaced with theme_light_100
$buttons-disabled: #ebebeb; //replaced with theme_light_50
$notification-affirmative: #40a434; //replaced with success_500
$notification-attention: #f97f15;   //replaced with theme_orange_700
$notification-error: #e42323;   //replaced with theme_red_600
$error-bg: #feeeee; //replaced with theme_light_25
$opportunity-co-founder: #ca2e55;   //replaced with theme_pink_700
$opportunity-partnership: #003f91;  //replaced with theme_blue_800
$opportunity-expert-advise: #f8971d;    //replaced with theme_orange_600
$opportunity-mentor: #833fff;   //replaced with theme_purple_500
$opportunity-connection: #0197f6;   //replaced with theme_blue_300
$opportunity-investment: #34ba92;   //replaced with theme_success_300
$opportunity-volunteer: #cd44db;    //replaced with theme_fushia_500
$opportunity-other: #b9beca;    //replaced with theme_light_300
$buttons-primary: #106fff;  //replaced with primary-600
$grey-bg-light-eb: #ebebeb; //replaced with theme_light_50
$white-bg-fff: #ffffff; // no need for this variable
$text-black: #111111;   //replaced with theme_black_900
$black: #000000;    //no need for this
$blue-darkest--500: #1F2C49;
$blue-darkest: #1d2a44;
$blue-dark: #223251;
$transparent: transparent;  // no need for this variable
$gray-600: #95a5a6; //replaced with theme_light_500
$gray-900: #f3f6f8; //replaced with theme_light_400
$white: #fff;   // no need for this variable
$black-100: #111;   // replaced with theme_black_900
$black-200: #222;   // replaced with theme_black_600
$black-300: #333;   // replaced with theme_black_500
$black-400: #444;   // replaced with theme_black_400
$black-500: #555;   // replaced with theme_black_300
$black-600: #666;   // replaced with theme_black_200
$black-900: #999;   // replaced with theme_black_100
$success: #40a434;  //replaced with success 500
$success-900: #f4faf3; //replaced with success 100
$success-light: rgba(64, 164, 52, 0.08);
$warning: #F39C12;  //replaced with theme_orange_500
$lightred: #FF7276; //replaced with theme_red_300
$danger: #E74C3C;   //replaced with theme_red_500
$orange : #f94d15;  //replaced with theme_orange_900
$primary-900: #293b61;  // added in tawilwind config
$primary-800: #3a59c3;  // added in tawilwind config
$primary-700: #106eff;  // added in tawilwind config
$primary-500: #3498db;  // added in tawilwind config
$primary-300: #aecdfe;  // added in tawilwind config
$primary-100: #f1f6ff;  // added in tawilwind config
$primary: #106fff;      // replaced with primary-600
$primary-light: rgba(52, 152, 219, 0.5);    //replaced with primary-200
$secondary: #293a61;    //replaced with secondary 600
$secondary-300: #106fff;    //replaced with primary-600
$secondary-400: #0966c2;    //replaced with primary 400
$secondary-500: #425374;    //replaced with secondary 500
$secondary-900: #316ea4;    //replaced with secondary 900
$secondary-1000: #e8f1ff;   //replaced with theme_light_100
$light-blue-100:rgba(232, 241, 255, 0.5);   //replaced with theme_light_75
$light-gray-dark:rgba(185, 190, 202, 0.5);  //replaced with theme_light_250
$dark: #767b87;
$dark--500: #868b95;    //not used anywhere
$dark--800: #e5eaef;    //not used anywhere
$dark-blue: #003f91;    //replaced with theme_blue_800
$purple: #833fff;   //replaced with theme_purple_500
$light-blue: #0197f6;   //replaced with theme_blue_300
$dark-pink: #cd44db;    //replaced with theme_fushia-500
$co-founder: #ca2e55;   //replaced with theme_pink_700
$connection: #0197f6;   //replaced with theme_blue_300
$expert-advice: #f8971d; //replaced with theme_orange_600
$investment: #34ba92;   //replaced with theme_success_300
$mentor: #833fff;      //replaced with theme_purple
$partnership: #003f91;  //replaced with theme_blue_500
$volunteer: #cd44db;    //replaced with theme_fushia-500
$other: #b9beca;    //replaced with theme_light_300
$all: #fff; //no need for this
$bg-gray-nav: #3C3F46;  //replaced with theme_grey_500
$bg-gray-disabled: #ebebeb; //replaced with theme_light_50
$text-gray-disabled: #9e9e9e;   //replaced with theme_light_150
$onboarding-input-gray: #11111129;  //replaced with theme_black_825
$onboarding-input-bg-gray: #D9D9D93D;   //replaced with onboarding_input
$onboarding-border-gray: #1111113D; //replaced with theme_black_850
$open-status: #ffbc53;  //replaced with theme_orange_400
$closed-status: #999999;    //replaced with theme_black_100
$in-demand-status: #ffbc53;  //replaced with theme_orange_400
$active-status: #ffbc53;  //replaced with theme_orange_400
$bg-yellow: #ffd000;    //replaced with theme_yellow_500
$in-demand-color: #f97f15;  //replaced with theme_orange_700
$btn-secondary-5: rgba(232, 241, 255, 0.5); // replaced with theme_grey-75
$notification-affirmative: #40a434; //replaced with success 500
$linkedin-primary: #0a66c2; //replaced with linkedin
$twitter-primary: #1fa1f1;  //replaced with twitter
$dropdown-bg: #1a1a1a;  //replaced with theme_black-800
$secondary-light: #e9f1fe;  //replaced with theme_light_125
$gray-400: #282c31; //replaced with theme_grey_800
$navigation_hover: rgba(255,255,255,.75);   //replaced with white_opacity_75
$secondary_dark_hover: rgba(255, 255, 255, 0.12);   //replaced with white_opacity_12
$textbox-active-border: rgba(255, 255, 255, 0.35);  //replaced with white_opacity_35
$textbox-active-shaddow: rgba(0, 0, 0, 0.12);   //replaced with black_opacity_12

$black_bg_hover: #333333;   //replaced with theme_black_500
$black_bg_active: #555555;  //replaced with theme_black_300
$border-onboarding-secondary: rgba(17, 17, 17, 0.16); //replaced with theme_grey_400
$primary-variant: #2A72F6; //replaced with theme_blue_600
$spacer: 4px;
$em-spacer: 2em;
$bright-green: #5BA145; //replaced with theme_success_400

$bg-light: #f3f4f6; //replaced with theme_grey_100
$bg-lightest: #fefefe; //replaced with theme_grey_50
$bg-dark-gray:#222222;  //replaced by theme_black_600
$bg-dark-onboarding:#23314e;    //not used anywhere
$btn-color--secondary: #666666; //replaced with theme_black_200
$btn-bg--active: #5c94ba;   //replaced with theme_skyblue_500
$bg-lightest--gray: #f9f9f9;    //replaced with theme_grey_25

$typeform-color: rgb(232, 241, 255);    //replaced with theme_light_100
$badge-color: #344e54;  //replaced with badge
$primary-color--hover: #0f62e4; //replaced with theme_blue_400
$primary-color--active: #0c57cb;    //replaced with theme_blue_500
$secondary-color--hover: #cfe3ff;   //replaced with theme_blue_100
$secondary-color--active: #b8d6ff;  //replaced with theme_blue_200
$special-btn: #FFCF87;  //replaced with theme_orange_200
$special-btn-text: #260745; //replaced with theme_purple_900
$problem-box-1: #663597;    //replaced with theme_purple_600
$problem-box-2: #531b8a;    //replaced with theme_purple_700
$circle-number: #430880;    //replaced with theme_purple_800
$dark-brown: #C15344;   //replaced with arbitary class in html
$sky-blue: #428AC5; //replaced with theme_skyblue_500
$light-black: #435359;  //replaced with arbitary class in html
$gray--light: #eaebed;  //not used anywhere
$gray--dark: #D6D6D6;   // replaced with arbitrary class in html


$font-family-bold: 'Helvetica Now Display Bold';
$font-family-semi-bold: 'Helvetica-Neue-Bold';
$font-family: 'Helvetica Now Display Regular';
$font-family-light: 'Helvetica-Neue-Light';

$font-size-sm: 12px;
$mmb-subheading-2: 14px;
$font-size-lg: 18px;

$color-muted: #aaa; //replaced with theme_grey_600
$border-color--light: rgba(151, 151, 151, 0.15);    //replaced with theme_light_650
$shadow-color: rgba(185, 190, 202, 0.5);    //replaced with theme_light_250
$light-white-tab: rgba(255, 255, 255, 0.08);    //replaced with white_opacity_8

$dark-blue :#141a2b;    //overriden by theme_blue_900
$light-white: #fffff0;  //replaced with theme_grey_125
$light-color : rgba(255, 255, 255, 0.12);   //replaced with white_opacity_12
$light-white-bg: rgba(255, 255, 255, 0.12); //replaced with white_opacity_12
$light-gray:rgba(185, 190, 202, 0.35);  //replaced with theme_light_250
$light-overlay: rgba(0, 0, 0, 0);   // no need for this variable
$dark-overlay: rgba(0, 0, 0, 0.5);  //replaced with black_opacity_50
$white-opacity-xs: rgba(255, 255, 255, 0.65);   //replaced with white_opacity_65
$modal-back-drop: rgba(41, 59, 97, 0.75);   //replaced with modal_backdrop
$toggle-background: rgba(120, 120, 128, 0.16);  //replaced with toggle background
$dissable-input-onboarding: #3e4e70;    //replaced with disabled_onboarding_input
$white-lightest: rgba(255, 255, 255, 0.5);  //replaced with white_opacity_50
$premium-bg: rgba(17, 17, 17, 0.75);    //not used anywhere
$light-border: rgba(255, 255, 255, 0.25);   //replaced with white_opacity_25
$light-background-onboarding: rgba(255, 255, 255, 0.15);    //replaced with white_opacity_15
$dissabled-textbox: rgba(255, 255, 255, 0.591);     //replaced with white_opacity_59
$white-opacity-onbording: rgba(255, 255, 255, 0.45);    //replaced with white_opacity_45
$about-section-bg-color: linear-gradient(0deg, #A8834E, #A8834E),
linear-gradient(203.12deg, rgba(240, 210, 105, 0.4) 10.45%, rgba(150, 131, 63, 0) 40.49%),
linear-gradient(0deg, #F9F9F9, #F9F9F9);    //replaced with about-section

$about-section-bg-color-2: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
linear-gradient(0deg, #6410B8, #6410B8);    //replaced with about-section-2

$about-problem-section: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
linear-gradient(0deg, #8710FF, #8710FF);    //replaced with about-problem

$home-inspired: linear-gradient(0deg, #252C34, #252C34),
radial-gradient(44.73% 44.73% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);    //replaced with home-inspired



$dark-bg: #262b3b;  //replaced with arbitary class in html
$modal-header-bg: rgba(234, 241, 254, 1);   //replaced with theme_light_100
$mobile_nav_hover_bg: rgba(255, 255, 255, 0.06);    //replaced with white_opacity_6

$nav-hover-dark: #2e2e2e;   //not used anywhere

// Padding and margin utils
$spacer: 4px; // multiples of this unitscale
$spacer-range-start: 1;
$spacer-range-end: 100;
