@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@angular/cdk/overlay-prebuilt.css";
@import './tw-fonts';
@import './tw-typography';
@import './tw-form-fields';
@import './modal.styles';
@import './tw-buttons';
@import '../core/slick-theme';
@import './tw_commons.scss';
@import '../core/scrollbar';
@import '../core/icons';
@import '../components/navbar';
@import '../core/mmb-cms';
@import '../core/typeform';

// to be removed after using cdk modal service we created
@import '../core/modal';